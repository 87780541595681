import React, { useEffect, useRef, useState } from 'react';
import useAuthenticatedRequest from '../../hooks/useAuthenticatedRequest';

import { Button } from '@material-ui/core';
import LaunchIcon from '@mui/icons-material/Launch';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AiFillFilePdf, AiFillIeCircle } from 'react-icons/ai';
import { FcApproval, FcInfo } from 'react-icons/fc';
import 'react-pivottable/pivottable.css';
import { Link } from 'react-router-dom';
import Select from 'react-select';

import { asyncForEach } from '../../helpers/asyncForEach';

function GetCases(props) {
  const [data, setData] = useState({});
  const [filteredData, setFilteredData] = useState({});
  const [customerNames, setCustomerNames] = useState({});
  const [projectNames, setProjectNames] = useState({});
  const [query, setQuery] = useState([]);
  const [customerQuery, setCustomerQuery] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [customerOption, setCustomerOption] = useState({});
  const statusUploadedAudioRef = useRef(null);
  const statusProcessedAudioRef = useRef(null);
  const statusRemainingAudioRef = useRef(null);
  const { request } = useAuthenticatedRequest();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [options, setOptions] = useState([
    { value: 'billable', label: 'Billable' },
    { value: 'archived', label: 'Archived' },
    { value: 'using', label: 'Using' },
    /*  { value: 'AT', label: 'AT' },
      { value: 'GB', label: 'GB' },
      { value: 'CH', label: 'CH' },
      { value: 'ES', label: 'ES' },
      
      { value: 'TR', label: 'TR' },
      { value: 'UA', label: 'UA' },
      { value: 'IN', label: 'IN' },*/
  ]);
  const additionalOption = { value: '', label: 'All Values' };

  const updatedOptions = [...options, additionalOption];
  const [selectedOption, setSelectedOption] = useState(null);
  const customers = async () => {
    let jsonString = encodeURIComponent(JSON.stringify(customerQuery));
    console.log(jsonString);
    const url = `https://dev.api.digicust.com/support-middleware/api/get-allCustomers/${jsonString}`;
    try {
      const response = await request(url);
      console.log(response);
      setCustomerOption(response);
    } catch (error) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    customers();
  }, [customerQuery]);

  const customerOptions = Object.values(customerOption).map((value, label) => ({
    value: value.id,
    label: value.name ?? value.id,
  }));

  const projectOptions = Object.entries(projectNames).map(([value, label]) => ({
    value,
    label: label ?? value,
  }));

  function changeHandler({ id, value }) {
    const newFilter = { field: id, condition: 'contains', value };
    console.log(newFilter);
    if (id !== 'flags') {
      setQuery((prevFilters) => {
        if (value === '') {
          return prevFilters.filter((filter) => filter.field !== id);
        }

        // Update existing filter if it exists, otherwise add new filter
        const index = prevFilters.findIndex((filter) => filter.field === id);
        if (index !== -1) {
          const updatedFilters = [...prevFilters];
          updatedFilters[index] = newFilter;
          return updatedFilters;
        } else {
          return [...prevFilters, newFilter];
        }
      });
    } else {
      setCustomerQuery((prevFilters) => {
        if (value === '') {
          return prevFilters.filter((filter) => filter.field !== id);
        }

        // Update existing filter if it exists, otherwise add new filter
        const index = prevFilters.findIndex((filter) => filter.field === id);
        if (index !== -1) {
          const updatedFilters = [...prevFilters];
          updatedFilters[index] = newFilter;
          return updatedFilters;
        } else {
          return [...prevFilters, newFilter];
        }
      });
    }
  }

  function handleSelect(selectedOption, id) {
    setSelectedOptions(selectedOption);

    if (!selectedOption || selectedOption.length === 0) {
      // If no options are selected, remove all filters with the given id
      setQuery((prevFilters) =>
        prevFilters.filter((filter) => filter.field !== id),
      );
      return;
    }

    const newFilters = selectedOption.map((option) => ({
      field: id,
      condition: 'contains',
      value: option.value,
    }));

    setQuery((prevFilters) => {
      const updatedFilters = prevFilters.filter(
        (filter) => filter.field !== id,
      );
      return [...updatedFilters, ...newFilters];
    });
  }

  const caseList = async () => {
    let jsonString = encodeURIComponent(JSON.stringify(query));

    console.log(jsonString);
    const url = `https://dev.api.digicust.com/support-middleware/api/case-list/${jsonString}`;
    try {
      const response = await request(url);
      setFilteredData(response);
      let customerIdArray = [];
      Object.values(response).map((value, key) =>
        customerIdArray.push(value.customerId),
      );
      let customerIds = customerIdArray.filter(
        (value, index, array) => array.indexOf(value) === index,
      );
      const newCustomerNames = {};
      await asyncForEach(customerIds, async (id) => {
        newCustomerNames[id] = (
          await request(
            `https://dev.api.digicust.com/support-middleware/api/customer/${id}/customer-name`,
          )
        )?.name;
      });
      console.log(newCustomerNames);
      setCustomerNames(newCustomerNames);
      let projectIdArray = [];
      Object.values(response).map((value, key) =>
        projectIdArray.push(value.projectId),
      );
      let projectIds = projectIdArray.filter(
        (value, index, array) => array.indexOf(value) === index,
      );
      const newProjectNames = {};
      await asyncForEach(
        await asyncForEach(customerIds, async (customerId, index) => {
          const projectId = projectIds[index];
          const projectName = (
            await request(
              `https://dev.api.digicust.com/support-middleware/api/customer/${customerId}/project/${projectId}/project-name`,
            )
          )?.alias;
          newProjectNames[projectId] = projectName;
        }),
      );
      console.log(newProjectNames);
      setProjectNames(newProjectNames);
    } catch (error) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    caseList();
  }, [query]);
  console.log(filteredData);

  function icon(string) {
    if (string === 'processed') return <FcInfo />;
    if (string === 'finished') return <FcApproval />;
    const subStr = string?.substring(string.length - 3);
    if ('pdf' === subStr) return <AiFillFilePdf />;
    if ('com' === subStr) return <AiFillIeCircle />;
  }
  function Colour(string) {
    if (string === 'extracting') return 'warning';
    if (string === 'finished') return 'success';
    if (string === 'uploaded') return 'warning';
    if (string === 'processed') return 'info';
  }
  function refreshPage() {
    caseList();
  }
  function caseDetail(value) {
    console.log(value);
  }

  const caseHandler = async () => {
    const url = `https://dev.api.digicust.com/support-middleware/api/get-cases`;
    const response = await request(url);
    console.log(response);
    if (Array.isArray(response) && response.length > 0) {
      Object.values(response).map((value, key) => {
        if (value.status.value === 'processed') {
          statusProcessedAudioRef.current.play();
        } else if (value.status.value === 'uploaded') {
          statusUploadedAudioRef.current.play();
        } else {
          statusRemainingAudioRef.current.play();
        }
      });
      //  refreshPage();
    }
  };
  setTimeout(caseHandler, 30000);

  /*let options = {}
   options=Object.values(customerOptions).map(([option, key]) => ({
    option,key
   }
  ))*/
  console.log(customerOptions);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} size='small' aria-label='a dense table'>
        <TableHead>
          <TableRow>
            <TableCell align='center'>
              <b>ID</b>
              <div>
                <input
                  type='text'
                  id='id'
                  onChange={(event) =>
                    changeHandler({
                      id: event.target.id,
                      value: event.target.value,
                    })
                  }
                  placeholder='Filter...'
                />
              </div>
            </TableCell>

            <TableCell align='center'>
              <b>Status</b>
              <select
                id='status.value'
                onChange={(event) =>
                  changeHandler({
                    id: event.target.id,
                    value: event.target.value,
                  })
                }
              >
                <option value=''>--All--</option>
                <option value='processed'>processed</option>
                <option value='uploaded'>uploaded</option>
                <option value='normalized'>normalized</option>
                <option value='extracted'>extracted</option>
                <option value='init'>init</option>
                <option value='checked'>checked</option>
                <option value='error'>error</option>
                <option value='aborted'>aborted</option>
                <option value='toEdit'>toEdit</option>
                <option value='inProgress'>inProgress</option>
                <option value='errorCustoms'>errorCustoms</option>
                <option value='errorInternal'>errorInternal</option>
                <option value='documentAnalysis'>documentAnalysis</option>
                <option value='physicalControl'>physicalControl</option>
                <option value='questionCustoms'>questionCustoms</option>
                <option value='firstClearance'>firstClearance</option>
                <option value='secondClearance'>secondClearance</option>
              </select>
              <div>
                <button onClick={caseHandler}>Updated Status</button>
                <audio
                  src={require('../../audio/click-124467.mp3')}
                  ref={statusProcessedAudioRef}
                />
                <audio
                  src={require('../../audio/new-message-125765.mp3')}
                  ref={statusUploadedAudioRef}
                />
                <audio
                  src={require('../../audio/achive-sound-132273.mp3')}
                  ref={statusRemainingAudioRef}
                />
              </div>
            </TableCell>
            <TableCell align='center'>
              <b>Reference</b>
              <div>
                <input
                  type='text'
                  id='reference'
                  onChange={(event) =>
                    changeHandler({
                      id: event.target.id,
                      value: event.target.value,
                    })
                  }
                  placeholder='Filter...'
                />
              </div>
            </TableCell>
            <TableCell align='center'>
              <b>CustomerName</b>
              <Select
                id='flags'
                options={updatedOptions}
                value={null}
                onChange={(selectedOption) => {
                  // Set selectedOptions to an empty array when an option is selected to clear any previous selections
                  changeHandler({ id: 'flags', value: selectedOption.value });
                }}
                placeholder={`Select an option${
                  selectedOption ? `: ${selectedOption.label}` : ''
                }`}
                isSearchable={false}
              />

              <div>
                <Select
                  id='customerId'
                  options={customerOptions}
                  placeholder='Select Customer'
                  value={selectedOptions}
                  onChange={(selectedOption) => {
                    handleSelect(selectedOption, 'customerId');
                  }}
                  isSearchable={true}
                  isMulti
                  styles={{
                    placeholder: (provided) => ({
                      ...provided,
                      fontSize: '2em',
                    }),
                  }}
                />
              </div>
            </TableCell>
            <TableCell align='center'>
              <b>ProjectName</b>
              <select id='projectId' onChange={changeHandler}>
                <option value=''>-- Select project --</option>
                {Object.values(projectOptions).map((option, key) => (
                  <option value={option.value}>{option.value}</option>
                ))}
              </select>
            </TableCell>
            <TableCell align='left'>
              <b>Filename & Status</b>
              <div>
                <input
                  type='text'
                  id='documents.documentStatus'
                  onChange={(event) =>
                    changeHandler({
                      id: event.target.id,
                      value: event.target.value,
                    })
                  }
                  placeholder='Filter...'
                />
              </div>
            </TableCell>
            <TableCell align='left'>
              <button onClick={refreshPage}>
                <b>Refresh page</b>
              </button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(filteredData).map((value, key) => (
            <TableRow>
              <TableCell>
                <Link to={`/caseFetch/${value.id}`}>
                  <Button variant='outlined' onClick={caseDetail}>
                    {value.id?.toString()}
                  </Button>
                </Link>
              </TableCell>
              <TableCell>
                <Stack>
                  <Chip
                    icon={icon(value?.status?.value?.toString())}
                    label={value.status?.value?.toString()}
                    color={Colour(value.status?.value?.toString())}
                    variant='outlined'
                  />
                </Stack>
              </TableCell>
              <TableCell align='center'>
                <Stack>
                  <Chip
                    icon={icon(value.reference?.toString())}
                    label={value.reference?.toString()}
                    variant='outline'
                  />
                </Stack>
              </TableCell>
              <TableCell align='center' style={{ color: 'red' }}>
                {customerNames[value.customerId] || value.customerId}
              </TableCell>
              <TableCell align='center' style={{ color: 'red' }}>
                {projectNames[value.projectId] || value.projectId}
              </TableCell>
              <TableCell>
                {Object.values(value.documents).map((subValue, index) => (
                  <Table size='small'>
                    <TableRow
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell>
                        <Chip
                          icon={icon(subValue?.fileName?.toString())}
                          label={subValue?.fileName?.toString()}
                          variant='outline'
                        />
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Chip
                            icon={icon(subValue?.documentStatus?.toString())}
                            label={subValue?.documentStatus?.toString()}
                            color={Colour(subValue?.documentStatus?.toString())}
                            variant='outlined'
                          />
                          <a
                            href={
                              'https://elis.rossum.ai/document/' +
                              subValue?.analyzerMetadata?.annotationId
                            }
                            target='_blank'
                            rel='noreferrer'
                          >
                            <Button
                              variant='outlined'
                              style={{ color: 'black', marginLeft: '8px' }} // Add margin here
                              size='small'
                              startIcon={<LaunchIcon />}
                            >
                              Rossum
                            </Button>
                          </a>
                        </div>
                      </TableCell>
                    </TableRow>
                  </Table>
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default GetCases;
