import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAuthenticatedRequest from '../../hooks/useAuthenticatedRequest';

function GetCustomerCaseCount() {
  const [customerCaseCount, setCustomerCaseCount] = useState([]);
  const [customerUploadCount, setCustomerUploadCount] = useState([]);

  const { id } = useParams();
  const { request } = useAuthenticatedRequest();
  const navigate = useNavigate();

  const fetchCustomerCaseCount = async () => {
    if (!id) {
      navigate(prompt('Enter customer id') || '');
    }
    if (id) {
      setCustomerCaseCount(
        await request(
          `https://dev.api.digicust.com/support-middleware/api/customer/${encodeURIComponent(
            id,
          )}/case-count`,
        ),
      );
      setCustomerUploadCount(
        await request(
          `https://dev.api.digicust.com/support-middleware/api/customer/${encodeURIComponent(
            id,
          )}/upload-count`,
        ),
      );
    }
  };

  useEffect(() => {
    fetchCustomerCaseCount();
  }, [id]);

  return (
    <Fragment>
      <Container maxWidth='sm'>
        <h1>Case Count for Customer {id} per month</h1>
        <TableContainer component={Paper}>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>Year</TableCell>
                <TableCell>Month</TableCell>
                <TableCell>Case Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customerCaseCount.map((month) => (
                <TableRow
                  key={JSON.stringify(month)}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{month.y}</TableCell>
                  <TableCell>{month.m}</TableCell>
                  <TableCell>{month.caseCount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <h1>Upload Count for Customer {id} per month</h1>
        <TableContainer component={Paper}>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>Year</TableCell>
                <TableCell>Month</TableCell>
                <TableCell>Upload Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customerUploadCount.map((month) => (
                <TableRow
                  key={JSON.stringify(month)}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{month.y}</TableCell>
                  <TableCell>{month.m}</TableCell>
                  <TableCell>{month.uploadCount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Fragment>
  );
}

export default GetCustomerCaseCount;
