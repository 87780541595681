import { Box, Button, List, ListItem } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Link, NavLink, Route, Routes } from 'react-router-dom';
import AdditionalCosts from './AdditionalCosts';
import JSONEditor from './JSONEditor';

function GlobalSettings() {
  const settings = [
    {
      path: 'additional-costs-keywords',
      title: 'Additional Costs Keywords',
      component: <AdditionalCosts />,
    },
    {
      path: 'import-sales-tax-rates',
      title: 'Import Sales Tax Rates',
      component: <JSONEditor id='importSalesTaxRates' isArray={true} />,
    },
    {
      path: 'countries',
      title: 'Countries',
      component: <JSONEditor id='countries' isArray={true} />,
    },
    {
      path: 'iata',
      title: 'IATA Rates',
      component: <JSONEditor id='IATARates' isArray={false} />,
    },
    {
      path: 'air-freight-cost-proportions',
      title: 'Air Freight Cost Proportions',
      component: <JSONEditor id='airFreightCostProportions' isArray={true} />,
    },
    {
      path: 'currencies',
      title: 'Currencies',
      component: <JSONEditor id='currencies' isArray={true} />,
    },
    {
      path: 'declarations-of-origin',
      title: 'Declarations of Origin',
      component: <JSONEditor id='declarationsOfOrigin' isArray={true} />,
    },
    {
      path: 'document-type-codes',
      title: 'Document Type Codes',
      component: <JSONEditor id='documentTypeCodes' isArray={true} />,
    },
    {
      path: 'package-types',
      title: 'Package Types',
      component: <JSONEditor id='packagetypes' isArray={true} />,
    },
    {
      path: 'units-of-measurement-dependencies',
      title: 'Units of Measurement Dependencies',
      component: (
        <JSONEditor id='unitsOfMeasurementDependencies' isArray={true} />
      ),
    },
    {
      path: 'units-of-measurement',
      title: 'Units of Measurement',
      component: <JSONEditor id='unitsOfMeasurement' isArray={true} />,
    },
    {
      path: 'treaty-mapping',
      title: 'Treaty Mappings',
      component: <JSONEditor id='treatyMapping' isArray={false} />,
    },
    {
      path: 'weight-units',
      title: 'Weight Units',
      component: <JSONEditor id='weightUnits' isArray={true} />,
    },
    {
      path: 'postcode-regex',
      title: 'Postcode Regex',
      component: <JSONEditor id='postcodeRegex' isArray={false} />,
    },
    {
      path: 'I0610',
      title: 'I0610',
      component: <JSONEditor id='I0610' isArray={true} />,
    },
  ];
  return (
    <Fragment>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Box
          sx={{ display: { xs: 'none', sm: 'block' } }}
          style={{
            boxShadow:
              '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
          }}
        >
          <List>
            {settings.map((setting) => (
              <ListItem
                key={'list_' + setting.path}
                button
                component={NavLink}
                to={setting.path}
              >
                {setting.title}
              </ListItem>
            ))}
          </List>
        </Box>
        <Box component='main' sx={{ flexGrow: 1, maxHeight: '100%' }}>
          <Routes>
            {settings.map((setting) => (
              <Route
                key={'route' + setting.path}
                path={setting.path}
                element={setting.component}
              />
            ))}
            <Route
              path=''
              element={
                <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                  <div>
                    {settings.map((setting) => (
                      <Link key={'button_' + setting.path} to={setting.path}>
                        <Button variant='outlined'>{setting.title}</Button>
                      </Link>
                    ))}
                  </div>
                </Box>
              }
            />
          </Routes>
        </Box>
      </Box>
    </Fragment>
  );
}

export default GlobalSettings;
