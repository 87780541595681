import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import axios from 'axios';

function useAuthenticatedRequest() {
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const account = useAccount(isAuthenticated && accounts[0]);

  const request = async (url, method = 'GET', data = undefined, options) => {
    return new Promise(function (resolve, reject) {
      instance
        .acquireTokenSilent({
          scopes: ['api://265be2b8-0864-44a9-ad72-165c93a81494/Admin'],
          account,
        })
        .then(async (tokenResponse) => {
          const res = await axios(url, {
            method,
            data,
            headers: {
              Authorization: `Bearer ${tokenResponse.accessToken}`,
            },
            ...options,
          });
          return resolve(res.data);
        })
        .catch(async (error) => {
          if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return instance.acquireTokenPopup({
              scopes: ['api://265be2b8-0864-44a9-ad72-165c93a81494/Admin'],
              account,
            });
          }
        })
        .catch((error) => {
          alert(error);
        });
    });
  };

  return { request };
}

export default useAuthenticatedRequest;
