import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { BrowserRouter } from 'react-router-dom';

const msalConfig = {
  auth: {
    clientId: 'ac84b69b-5ec5-4347-bf1c-60ed1ad94e1f',
    authority:
      'https://login.microsoftonline.com/bf2e212e-cf03-4606-beaf-7715d6f28009',
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

const pca = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <MsalProvider instance={pca}>
        <App />
      </MsalProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
