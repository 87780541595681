import { Button, ButtonGroup, Tooltip } from '@material-ui/core';
import Editor from '@monaco-editor/react';
import LaunchIcon from '@mui/icons-material/Launch';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import jsonata from 'jsonata';
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from '../../App.module.css';
import useAuthenticatedRequest from '../../hooks/useAuthenticatedRequest';

function CaseFetch() {
  const [caseData, setCaseData] = useState('');
  const [caseDataObject, setCaseDataObject] = useState({});
  const { id } = useParams();
  const { request } = useAuthenticatedRequest();
  const navigate = useNavigate();
  const [mapping, setMapping] = useState(`{
    "items": aggregated.items.code.value,
    "documents": documents.documentType
}`);
  const [output, setOutput] = useState('');

  const calculateOutput = async (
    _mapping,
    _originalDataStructure,
    outputFormat,
  ) => {
    const jsonataMapping = jsonata(_mapping);
    let newOutput = jsonataMapping.evaluate(JSON.parse(_originalDataStructure));
    setOutput(JSON.stringify(newOutput, null, 4));
  };

  useEffect(() => {
    calculateOutput(mapping, caseData);
  }, [mapping, caseData]);

  const fetchCase = async () => {
    if (!id) {
      navigate(prompt('Enter case id') || '');
    }
    if (id) {
      const data = await request(
        'https://dev.api.digicust.com/support-middleware/api/get-case/' +
          encodeURIComponent(id),
      );
      setCaseData(JSON.stringify(data, null, 4));
      setCaseDataObject(data);
    }
  };

  const triggerCase = async (env) => {
    if (!id) {
      navigate(prompt('Enter case id') || '');
    }
    if (id) {
      const data = await request(
        'https://dev.api.digicust.com/support-middleware/api/trigger-case/' +
          encodeURIComponent(id) +
          '/' +
          encodeURIComponent(env),
      );
    }
    alert('Case successfully triggered.');
  };

  useEffect(() => {
    fetchCase();
  }, [id]);

  return (
    <Fragment>
      <div className={styles.rightControl}>
        <div className={styles.editorWrapper}>
          <ButtonGroup size='small' aria-label='primary button group'>
            {caseDataObject?.documents
              ?.filter((doc) => doc.analyzerMetadata?.annotationId)
              ?.map((doc) => (
                <a
                  href={
                    'https://elis.rossum.ai/document/' +
                    doc?.analyzerMetadata?.annotationId
                  }
                  target='_blank'
                  rel='noreferrer'
                >
                  <Button
                    style={{ color: 'white' }}
                    size='small'
                    startIcon={<LaunchIcon />}
                  >
                    Rossum
                  </Button>
                </a>
              ))}
            <a
              href={
                'https://app.digicust.com/' +
                caseDataObject?.customerId +
                '/' +
                caseDataObject?.projectId +
                '/cases/case/' +
                id +
                '/items'
              }
              target='_blank'
              rel='noreferrer'
            >
              <Button
                style={{ color: 'white' }}
                size='small'
                startIcon={<LaunchIcon />}
              >
                Digicust APP
              </Button>
            </a>
            <a>
              <Tooltip
                title='Trigger case from Data Normalization onwards. Re-triggers all events.'
                startIcon={<LaunchIcon />}
              >
                <Button
                  style={{ color: 'white' }}
                  onClick={() => triggerCase('dev')}
                  size='small'
                  startIcon={<PlayArrowIcon />}
                >
                  Trigger Dev
                </Button>
              </Tooltip>
            </a>
            <a>
              <Tooltip
                title='Trigger case from Data Normalization onwards. Re-triggers all events.'
                startIcon={<LaunchIcon />}
              >
                <Button
                  style={{ color: 'white' }}
                  onClick={() => triggerCase('prod')}
                  size='small'
                  startIcon={<PlayArrowIcon />}
                >
                  Trigger Prod
                </Button>
              </Tooltip>
            </a>
          </ButtonGroup>
          <div className={styles.editorWrapper}>
            {/* <Draggable
              // className={styles.caseButtons}
              axis='x'
              handle='.handleDrag'
              defaultPosition={{ x: 50, y: 10 }}
              children={
                <Fragment>
                  <DragIndicatorIcon className='handleDrag' />
                  <ButtonGroup
                    size='small'
                    variant='contained'
                    aria-label='primary button group'>
                    {caseDataObject?.documents?.[0]?.analyzerMetadata
                      ?.annotationId && (
                      <a
                        href={
                          "https://elis.rossum.ai/document/" +
                          caseDataObject?.documents?.[0]?.analyzerMetadata
                            ?.annotationId
                        }
                        target='_blank'
                        rel='noreferrer'>
                        <Button>Open in Rossum</Button>
                      </a>
                    )}
                  </ButtonGroup>
                </Fragment>
              }
            /> */}
            <Editor
              path='originalDataStructure'
              className={styles.editor}
              value={caseData}
              theme='vs-dark'
              onChange={(value, event) => {
                setCaseData(value);
              }}
              language='json'
            />
          </div>
        </div>
      </div>
      <div className={styles.rightControl}>
        <div className={styles.editorWrapper}>
          <div className={styles.actions}>
            <span>Mapping Definition</span>
          </div>
          <Editor
            path='mapping'
            theme='vs-dark'
            className={styles.editor}
            value={mapping}
            onChange={(value, event) => {
              setMapping(value);
            }}
            language=''
          />
        </div>
        <div className={styles.editorWrapper}>
          <div className={styles.actions}>
            <span>Output</span>
          </div>
          <Editor
            path='newDataStructure'
            className={styles.editor}
            value={output}
            theme='vs-dark'
            onChange={(value, event) => {
              setOutput(value);
            }}
            language='json'
          />
        </div>
      </div>
    </Fragment>
  );
}

export default CaseFetch;
