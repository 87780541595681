import Container from '@mui/material/Container';
import fileDownload from 'js-file-download';
import React, { Fragment, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAuthenticatedRequest from '../../hooks/useAuthenticatedRequest';

function GetBulkPDFs() {
  const { id, year, month } = useParams();
  const { request } = useAuthenticatedRequest();
  const navigate = useNavigate();

  const fetchBulkPDFs = async () => {
    let newId, newYear, newMonth;
    if (!id) {
      newId = prompt('Enter customer id') || '';
      navigate(newId);
    }
    if (!year) {
      newYear = prompt('Enter year (e.g. 2022)') || '';
      navigate(`${id || newId}/${newYear}`);
    }
    if (!month) {
      newMonth = prompt('Enter month (e.g. 6)') || '';
      navigate(`${id || newId}/${year || newYear}/${newMonth}`);
    }
    if (id && year && month) {
      fileDownload(
        await request(
          `https://dev.api.digicust.com/support-middleware/api/customer/${encodeURIComponent(
            id,
          )}/${year}/${month}/get-bulk-pdfs`,
          undefined,
          undefined,
          { responseType: 'blob' },
        ),
        `${id}-${year}/${month}.zip`,
      )();
    }
  };

  useEffect(() => {
    fetchBulkPDFs();
  }, [id, year, month]);

  return (
    <Fragment>
      <Container>
        <h1>
          Fetch Bulk PDFs of{id} for {month}-{year}{' '}
        </h1>
        <p>This can take some minutes</p>
      </Container>
    </Fragment>
  );
}

export default GetBulkPDFs;
