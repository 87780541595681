import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import {
  AppBar,
  Button,
  Container,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import React, { Fragment } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import styles from './App.module.css';
import Dashboard from './Dashboard';
import GetBulkPDFs from './Modules/BulkPDFs/GetBulkPDFs';
import GetCustomerCaseCount from './Modules/CaseCount/GetCustomerCaseCount';
import CaseFetch from './Modules/CaseFetch/CaseFetch';
import GetCustomerDocumentCount from './Modules/DocumentCount/GetCustomerDocumentCount';
import GetCases from './Modules/GetCase/GetCases';
import GlobalSettings from './Modules/GlobalSettings';

function App() {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();
  const [appsAnchorEl, setAppsAnchorEl] = React.useState(null);

  const appsOpen = Boolean(appsAnchorEl);
  return (
    <div className={styles.app}>
      <AppBar position='static'>
        <Toolbar className={styles.toolbar}>
          <Link to='/'>
            <Typography variant='h6'>Digicust Support Tool</Typography>
          </Link>
          <div>
            {isAuthenticated ? (
              <Fragment>
                <Button
                  color='inherit'
                  id='demo-positioned-button'
                  aria-controls={appsOpen ? 'demo-positioned-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={appsOpen ? 'true' : undefined}
                  onClick={(event) => setAppsAnchorEl(event.currentTarget)}
                >
                  Applets
                </Button>
                <Menu
                  id='demo-positioned-menu'
                  aria-labelledby='demo-positioned-button'
                  anchorEl={appsAnchorEl}
                  open={appsOpen}
                  onClose={() => setAppsAnchorEl(null)}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Link to='/caseFetch'>
                    <MenuItem onClick={() => setAppsAnchorEl(null)}>
                      Fetch Case
                    </MenuItem>
                  </Link>
                  <Link to='/get-customer-case-count'>
                    <MenuItem onClick={() => setAppsAnchorEl(null)}>
                      Get Customer Case Count
                    </MenuItem>
                  </Link>
                  <Link to='/global-settings'>
                    <MenuItem onClick={() => setAppsAnchorEl(null)}>
                      Global Settings
                    </MenuItem>
                  </Link>
                  <Link to='/get-customer-document-count'>
                    <MenuItem onClick={() => setAppsAnchorEl(null)}>
                      Get Customer Document Count
                    </MenuItem>
                  </Link>
                  <Link to='/get-bulk-pdfs'>
                    <MenuItem onClick={() => setAppsAnchorEl(null)}>
                      Get Bulk PDFs
                    </MenuItem>
                  </Link>
                  <Link to='/get-cases'>
                    <MenuItem onClick={() => setAppsAnchorEl(null)}>
                      Cases
                    </MenuItem>
                  </Link>
                </Menu>
                <Button color='inherit' onClick={() => instance.logout()}>
                  Logout
                </Button>
              </Fragment>
            ) : null}
          </div>
        </Toolbar>
      </AppBar>
      <div className={styles.main}>
        {isAuthenticated ? (
          <Routes>
            <Route path='/' element={<Dashboard />} />
            <Route path='caseFetch' element={<CaseFetch />} />
            <Route path='caseFetch/:id' element={<CaseFetch />} />
            <Route path='global-settings/*' element={<GlobalSettings />} />
            <Route
              path='get-customer-case-count'
              element={<GetCustomerCaseCount />}
            />
            <Route
              path='get-customer-case-count/:id'
              element={<GetCustomerCaseCount />}
            />
            <Route
              path='get-customer-document-count'
              element={<GetCustomerDocumentCount />}
            />
            <Route
              path='get-customer-document-count/:id/:year/:month'
              element={<GetCustomerDocumentCount />}
            />
            <Route path='get-bulk-pdfs' element={<GetBulkPDFs />} />
            <Route
              path='get-bulk-pdfs/:id/:year/:month'
              element={<GetBulkPDFs />}
            />
            <Route path='get-cases/' element={<GetCases />} />
          </Routes>
        ) : inProgress === 'login' ? (
          'Login in progress'
        ) : (
          <Fragment>
            <Container maxWidth='sm'>
              <h1>Login</h1>
              <span>There are currently no users signed in! </span>
              <Button
                mt={3}
                variant='contained'
                onClick={() => {
                  try {
                    instance.loginPopup({
                      scopes: [
                        'api://265be2b8-0864-44a9-ad72-165c93a81494/Admin',
                      ],
                    });
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                Login
              </Button>
            </Container>
          </Fragment>
        )}
      </div>
    </div>
  );
}

export default App;
