import { Button, Container } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

function Dashboard() {
  return (
    <Fragment>
      <Container>
        <h1>Dashboard</h1>
        <p>
          <Link to='/caseFetch'>
            <Button variant='outlined'>Fetch Case</Button>
          </Link>
          <Link to='/get-customer-case-count'>
            <Button variant='outlined'>Get Customer Case Count</Button>
          </Link>
          <Link to='/global-settings'>
            <Button variant='outlined'>Global Settings</Button>
          </Link>
          <Link to='/get-customer-document-count'>
            <Button variant='outlined'>Get Customer Document Count</Button>
          </Link>
          <Link to='/get-bulk-pdfs'>
            <Button variant='outlined'>Get Bulk PDFs</Button>
          </Link>
          <Link to='/get-cases'>
            <Button variant='outlined'>Get Cases</Button>
          </Link>
        </p>
      </Container>
    </Fragment>
  );
}

export default Dashboard;
