import React, { Fragment, useEffect, useState } from 'react';
import useAuthenticatedRequest from '../../hooks/useAuthenticatedRequest';

import { Button } from '@material-ui/core';
import Editor from '@monaco-editor/react';
import styles from '../../App.module.css';

function JSONEditor({ id, isArray }) {
  const [data, setData] = useState({});
  const { request } = useAuthenticatedRequest();

  const fetch = async () => {
    const resp = await request(
      'https://dev.api.digicust.com/support-middleware/api/global-setting/' +
        id,
    );
    const newData = isArray ? resp[id] : resp || {};
    setData(newData);
  };

  const save = async () => {
    const resp = await request(
      'https://dev.api.digicust.com/support-middleware/api/global-setting',
      'POST',
      { ...(isArray ? { [id]: data } : data), id },
    );
    console.log(resp, null, 4);
    if (resp) {
      alert('Successfully saved');
    }
  };

  useEffect(() => {
    fetch();
  }, [id, isArray]);

  return (
    <Fragment>
      <div
        className={styles.editorWrapper}
        style={{ maxHeight: '100%', width: '100%', display: 'flex' }}
      >
        <Editor
          path={id}
          value={JSON.stringify(data, undefined, 2)}
          className={styles.editor}
          onChange={(value, event) => {
            setData(JSON.parse(value));
          }}
          theme='vs-dark'
          language='json'
        />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            onClick={save}
            variant='contained'
            color='primary'
            style={{ margin: 10, color: 'white' }}
          >
            Save
          </Button>
        </div>
      </div>
    </Fragment>
  );
}

export default JSONEditor;
