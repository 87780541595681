import DeleteIcon from '@mui/icons-material/Delete';
import React, { Fragment, useEffect, useState } from 'react';
import useAuthenticatedRequest from '../../hooks/useAuthenticatedRequest';

import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';

function AdditionalCosts() {
  const [keywords, setKeywords] = useState([]);
  const { request } = useAuthenticatedRequest();

  const fetchKeywords = async () => {
    const resp = await request(
      'https://dev.api.digicust.com/support-middleware/api/global-setting/additional-costs-keywords',
    );
    console.log(resp, null, 4);
    setKeywords(resp?.keywords || []);
  };

  const saveKeywords = async () => {
    const resp = await request(
      'https://dev.api.digicust.com/support-middleware/api/global-setting',
      'POST',
      { id: 'additional-costs-keywords', keywords },
    );
    console.log(resp, null, 4);
    if (resp) {
      alert('Successfully saved');
    }
    setKeywords(resp?.keywords || [])``;
  };

  useEffect(() => {
    fetchKeywords();
  }, []);

  const addNewKeyword = () => {
    const keyword = prompt('Enter keyword');
    if (keyword) {
      const regex = keyword.replace(/[^0-9a-zA-Z]/gi, '.*');
      setKeywords([...keywords, { regex }]);
    }
  };

  const removeKeyword = (index) => {
    const newArray = [...keywords];
    newArray.splice(index, 1);
    setKeywords(newArray);
  };
  return (
    <Fragment>
      <div style={{ maxHeight: '100%', width: '100%', display: 'flex' }}>
        <Box
          style={{
            flexGrow: 1,
            maxWidth: 360,
            maxHeight: '100%',
            overflowY: 'auto',
          }}
        >
          <List dense>
            {keywords.map((keyword, i) => (
              <ListItem key={`keyword${i}`}>
                <ListItemText primary={keyword.regex} />
                <ListItemSecondaryAction>
                  <IconButton onClick={() => removeKeyword(i)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            onClick={addNewKeyword}
            variant='outlined'
            style={{ margin: 10 }}
          >
            New Keyword
          </Button>
          <Button
            onClick={saveKeywords}
            variant='contained'
            color='primary'
            style={{ margin: 10 }}
          >
            Save
          </Button>
        </div>
      </div>
    </Fragment>
  );
}

export default AdditionalCosts;
