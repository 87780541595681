import Container from '@mui/material/Container';
import React, { Fragment, useEffect, useState } from 'react';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import TableRenderers from 'react-pivottable/TableRenderers';
import 'react-pivottable/pivottable.css';
import Plot from 'react-plotly.js';
import { useNavigate, useParams } from 'react-router-dom';
import useAuthenticatedRequest from '../../hooks/useAuthenticatedRequest';

const PlotlyRenderers = createPlotlyRenderers(Plot);

function GetCustomerDocumentCount() {
  const [documentCount, setDocumentCount] = useState([]);

  const [pivotState, setPivotState] = useState({});
  const { id, year, month } = useParams();
  const { request } = useAuthenticatedRequest();
  const navigate = useNavigate();

  const fetchDocumentCount = async () => {
    let newId, newYear, newMonth;
    if (!id) {
      newId = prompt('Enter customer id') || '';
      navigate(newId);
    }
    if (!year) {
      newYear = prompt('Enter year (e.g. 2022)') || '';
      navigate(`${id || newId}/${newYear}`);
    }
    if (!month) {
      newMonth = prompt('Enter month (e.g. 6)') || '';
      navigate(`${id || newId}/${year || newYear}/${newMonth}`);
    }
    if (id && year && month) {
      setDocumentCount(
        await request(
          `https://dev.api.digicust.com/support-middleware/api/customer/${encodeURIComponent(
            id,
          )}/${year}/${month}/case-count`,
        ),
      );
    }
  };

  useEffect(() => {
    fetchDocumentCount();
  }, [id, year, month]);

  return (
    <Fragment>
      <Container>
        <h1>
          Document Count for Customer {id} for {month}-{year}{' '}
        </h1>
        <PivotTableUI
          data={documentCount || []}
          onChange={(s) => setPivotState(s)}
          renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
          {...pivotState}
        />
      </Container>
    </Fragment>
  );
}

export default GetCustomerDocumentCount;
